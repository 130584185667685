.field-item__image .ant-upload-list-picture .ant-upload-list-item-thumbnail img {
  max-width: 48px;
  max-height: 48px;
  width: auto;
  height: auto;
}
.field-item__image .ant-upload-list-picture .ant-upload-list-item-thumbnail {
  display: flex;
  align-items: center;
}
