.form-field__item.ant-form-item {
  margin-bottom: 2px;
}
.form-field__item.ant-form-item .ant-form-item-label > label {
  font-size: 12px;
  font-weight: 500;
  color: #42526e;
}
.form-field__item.ant-form-item .ant-form-explain,
.form-field__item .ant-form-extra {
  font-size: 12px;
  font-weight: 500;
}
.ant-form-vertical .form-field__item.ant-form-item .ant-form-item-label {
  padding-bottom: 4px;
}
.form-field__item.ant-form-item .ant-input {
  height: 34px;
  line-height: 34px;
  box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.16);
  font-size: 13px;
  font-weight: 500;
  color: #333333;
}
.form-field__item.ant-form-item textarea.ant-input {
  height: auto;
  line-height: 1.5;
}
.form-field__item.ant-form-item textarea.ant-input::placeholder,
.form-field__item.ant-form-item .ant-input::placeholder {
  color: rgba(0, 0, 0, 0.25);
}
.form-field__item.ant-form-item .ant-input:active,
.form-field__item.ant-form-item .ant-input:focus {
  border-color: #b4d4fd;
}
