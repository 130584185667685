.field-item__boolean.ant-form-item {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.field-item__boolean.ant-form-item .ant-form-item-label {
  margin-left: 10px;
}
.ant-form-vertical .field-item__boolean.form-field__item.ant-form-item .ant-form-item-label {
  padding-bottom: 0;
}
.field-item__boolean.form-field__item.ant-form-item .ant-form-item-label > label {
  font-size: 13px;
  font-weight: 600;
  color: #333333;
  cursor: pointer;
}
