.page-form {
  max-width: 850px;
  margin: 0 auto;
}

.page-spin {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-generator {
  padding: 0 0 25px 0;
}

/* utilities classes */

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.flex-column {
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: end;
}

.align-items-center {
  align-items: center;
}
.gap-8 {
  gap: 8px;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.vh-100 {
  height: 100vh;
}

.fw-500 {
  font-weight: 500;
}

.mb-20 {
  margin-bottom: 20px;
}

.mw-300 {
  max-width: 300px;
}

.text-nowrap {
  white-space: nowrap;
}

.pb-15 {
  padding-bottom: 15px;
}

.w-100 {
  width: 100%;
}

.root-spinner {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
