.form-builder__container {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.form-builder__content {
  width: 100%;
  background-color: #fff;
}

.empty-form-item {
  margin-bottom: 0 !important;
}
.empty-form-item .ant-form-item-label {
  padding-bottom: 0 !important;
}
.empty-form-item .ant-form-item-control-input {
  min-height: unset;
  height: 0;
}

.ant-form-item-extra {
  font-weight: 400;
}
