.form-media-select__filter {
  display: flex;
  gap: 8px;
  justify-content: space-between;
}

.form-media-select__filter .ant-input-group-wrapper {
  flex: 1;
}

.form-media-select__filter .ant-input-group-wrapper .ant-input-affix-wrapper,
.form-media-select__filter .ant-input-group-wrapper .ant-btn {
  height: 34px;
}

.form-media-select__table .ant-table-row {
  cursor: pointer;
}