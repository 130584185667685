.modal-code-editor .ant-modal-body {
  padding: 0;
}
.modal-code-editor .ant-modal-close-x {
  width: 43px;
  height: 43px;
  line-height: 43px;
}
.modal-code-tabs .ant-tabs-bar {
  margin-bottom: 0;
}
.modal-code-logs__container {
  min-height: 450.58px;
  overflow: auto;
  padding: 15px;
}
