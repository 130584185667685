.key-value-field__input-container {
  border: 1px dashed #cbd6e2;
	border-radius: 4px;
  padding: 4px;
	display: flex;
	gap: 4px;
}

.key-value-field__list {
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-bottom: 8px;
	max-height: 300px;
	overflow: auto;
}

.key-value-field__input {
	box-shadow: none !important;
	border-radius: 4px !important;
}

.key-value-field__remove-button {
	border: 1px dashed #cbd6e2;
	border-radius: 4px;
	font-size: 16px;
	padding: 8px;
	transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.key-value-field__remove-button:hover {
	border-color: #2372d9;
	color: #2372d9;
}

.key-value-field__add-button {
	background-color: transparent !important;
	box-shadow: none !important;
}

